<template>
  <main>
    <b-card>
      <b-container>
        <b-card-header class="align-items-center custom-card-header">
          <h1 class="mb-0 d-block grow">Agregar producto</h1>
          <b-button variant="primary" @click="handleProducts">
            <span>Productos seleccionados</span>
            <feather-icon
              icon="ShoppingCartIcon"
              class="ml-50"
              size="15"
              :badge="productsCount"
            />
          </b-button>
        </b-card-header>
      </b-container>
      <b-card-body>
        <add-store-products />
      </b-card-body>
    </b-card>

    <automatic-restocking-review-products />
    <b-card>
      <b-container>
        <div class="d-flex justify-content-between custom-buttons">
          <b-button
            variant="primary"
            class="d-flex align-items-center button"
            :to="{
              name: 'store-automatic-restocking',
              params: { id: $route.params.id },
            }"
          >
            <feather-icon icon="ArrowLeftIcon" size="20" />
            <span class="align-middle ml-50">Volver</span>
          </b-button>
          <b-button
            variant="success"
            class="d-flex align-items-center button"
            @click="handleAddProducts"
            :disabled="!productsCount"
          >
            <span class="align-middle mr-50">Agregar productos</span>
            <feather-icon icon="ArrowRightIcon" size="20" />
          </b-button>
        </div>
      </b-container>
    </b-card>
  </main>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"

import AddStoreProducts from "@/views/stores/AddStoreProductsToAutomaticRestockingSearchBar.vue"
import AutomaticRestockingReviewProducts from "@/@core/components/automatic-restocking/AutomaticRestockingReviewProducts.vue"

import { required } from "@validations"

export default {
  data() {
    return {
      automatic_restocking: {
        automatic_restocking_id: null,
        restocking_store_products_attributes: [
          {
            store_product_id: null,
            threshold_inventory: null,
            minimum_stock: null,
          },
        ],
      },
    }
  },

  components: {
    AddStoreProducts,
    AutomaticRestockingReviewProducts,
  },

  computed: {
    ...mapGetters("automaticRestocking", [
      "restockingStoreProducts",
      "productsCount",
    ]),
  },
  methods: {
    ...mapActions("automaticRestocking", ["updateAutomaticRestockingProduct"]),
    ...mapMutations("automaticRestocking", ["clearRestockingStoreProducts"]),
    handleProducts() {
      if (this.productsCount > 0) {
        this.$bvModal.show("review-products")
      } else {
        this.$swal({
          text: "No hay productos en  el carrito",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        })
      }
    },
    handleAddProducts() {
      this.automatic_restocking.restocking_store_products_attributes =
        this.restockingStoreProducts
      this.automatic_restocking.automatic_restocking_id =
        this.$route.params.order_id

      this.updateAutomaticRestockingProduct(this.automatic_restocking)
        .then((res) => {
          this.$swal({
            text: "Pedido automático creado con exito!",
            title: "Pedido automático creado con exito!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
          this.$router.push({
            name: "store-automatic-restocking",
            params: { id: this.$route.params.id },
          })
        })
        .catch((error) => {
          const msg = error.response.data.messages
          console.log(msg)
          this.$swal({
            title: "ERROR!",
            text: `${msg}` || msg[0],
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-card-header {
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1rem;

    h1 {
      text-align: center;
    }
  }
}

.custom-buttons {
  @media screen and (max-width: 640px) {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 1rem;

    .button {
      justify-content: center;
      width: 100%;
    }
  }
}

.button {
  width: fit-content;
}
</style>
